import { Logger } from '@lambda/commons/logging/logger';
import axios from 'axios';
import { ProductCategoryWarranty } from '@lambda/apis/src/category/types';
import settings from '@/settings';

const logger = new Logger('warranty-api');

export const getWarrantyInfoByCategoryPk = async (categoryPk?: string) => {
  try {
    if (!['reebelo-us', 'reebelo-ca'].includes(settings.store)) return null;
    if (!categoryPk) return null;

    const response = await axios.get<ProductCategoryWarranty>(
      `/api/warranty/${categoryPk}`,
    );

    const warrantyInfo = response.data;

    console.log('getWarrantyInfoByCategoryPk', { warrantyInfo });

    return warrantyInfo;
  } catch (err: any) {
    logger.error({ err }, 'error fetching warranty info by category pk');

    return null;
  }
};

export const getWarrantyInfoByCategoryPks = async (categoryPks: string[]) => {
  try {
    if (!['reebelo-us', 'reebelo-ca'].includes(settings.store)) return null;

    const response = await axios.post<ProductCategoryWarranty[]>(
      '/api/warranty',
      { categoryPks },
    );

    const warrantyInfoArray = response.data;

    console.log('getWarrantyInfoByCategoryPks', { warrantyInfoArray });

    return warrantyInfoArray;
  } catch (err: any) {
    logger.error({ err }, 'error fetching warranty info by category pks');

    return null;
  }
};

export const formatWarrantyInfo = (
  warrantyInfo?: ProductCategoryWarranty | null,
  mixedCart?: boolean,
) => {
  // TODO: Placeholder logic for now until we determine products that are actually not eligible for warranty
  if (
    !warrantyInfo ||
    !warrantyInfo.eligible ||
    !warrantyInfo.warrantyLength ||
    !warrantyInfo.warrantyUnit
  )
    return `${mixedCart ? 'Up to ' : ''}${settings.warranty_month} months`;

  const { warrantyUnit } = warrantyInfo;
  const { warrantyLength } = warrantyInfo;

  const formattedString = `${
    mixedCart ? 'Up to ' : ''
  }${warrantyLength} ${warrantyUnit}`;

  if (warrantyLength > 1) return `${formattedString}s`;

  return formattedString;
};
